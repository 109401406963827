import React, { useEffect, useState } from "react";
import {
  DivInfoClient,
  DivInfoClientTable,
  DivInfoClientCard,
  DivDetailClient,
  DivIdClient,
  DivInfoDetailsClient,
  DivBtn,
  IdSpan,
  NameClient,
  Value,
  InfoValue,
  LinkPage,
  DivOrgLoading,
  PriceBuyed,
  DivOrgValues,
  DivOrgInfo,
  DivFilter,
  DivOrgFilter,
  LabelFilter,
  InputFilter,
  BtnFilter,
  DivOrgBtn,
  BtnCancel,
  DivOrgSales,
} from "./ClientResumeStyle";

import { ClipLoader } from "react-spinners";
import { Close, Search } from "@styled-icons/material";

export default function ClientResume(props) {
  const infoClientFinancial = props.ordersClients;

  const [loadingOrders, setLoadingOrders] = useState(false);
  const [showList, setShowList] = useState(false);
  const [filterNameClient, setFilterNameClient] = useState("");
  const [filterInfoClient, setFilterInfoClient] = useState([]);

  const createListOrders = (dataClient) => {
    setLoadingOrders(true);
    setShowList(true);
    if (showList) {
      setFilterInfoClient(dataClient);
    }
    setTimeout(() => {
      setLoadingOrders(false);
    }, 1000);
  };

  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } else {
      fullName = firstName;
    }
    const formatName = fullName?.split(" ");
    for (var i = 0; i < formatName?.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName?.join(" ");

    return result;
  };

  const filterClientResume = () => {
    const filterList = infoClientFinancial.filter((clientResume) =>
      filterNameClient.length > 0
        ? parseName(clientResume.nameClient, clientResume.lastName).includes(
            parseName(filterNameClient)
          )
        : clientResume
    );
    setFilterInfoClient(filterList);
  };

  useEffect(() => {
    createListOrders(infoClientFinancial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoClientFinancial]);

  return (
    <DivOrgSales>
      <DivFilter show={props.disableFilter}>
        <DivOrgFilter>
          <LabelFilter>Nome</LabelFilter>
          <InputFilter
            value={filterNameClient}
            onChange={(e) => setFilterNameClient(e.target.value)}
          />
          <DivOrgBtn>
            <BtnFilter type="button" onClick={filterClientResume}>
              <Search />
            </BtnFilter>
            <BtnCancel
              type="button"
              onClick={() => {
                setFilterNameClient("");
                setFilterInfoClient(infoClientFinancial);
              }}
            >
              <Close />
            </BtnCancel>
          </DivOrgBtn>
        </DivOrgFilter>
      </DivFilter>
      <DivInfoClient>
        <DivInfoClientTable>
          {loadingOrders ? (
            <DivOrgLoading>
              <ClipLoader speedMultiplier={3} color={"#FFF"} />
            </DivOrgLoading>
          ) : (
            filterInfoClient.map((infoOrders, index) => {
              return (
                <DivInfoClientCard key={index}>
                  <DivDetailClient>
                    <DivIdClient>
                      <IdSpan>{index + 1}</IdSpan>
                    </DivIdClient>
                    <DivInfoDetailsClient>
                      <NameClient>
                        {parseName(infoOrders.nameClient, infoOrders.lastName)}
                      </NameClient>
                      <DivOrgInfo>
                        <DivOrgValues>
                          <Value>Valor Comprado</Value>
                          <PriceBuyed
                            displayType="text"
                            value={infoOrders.totalBuyed}
                            decimalSeparator=","
                            fixedDecimalScale
                            thousandSeparator="."
                            decimalScale={2}
                            prefix={"R$ "}
                          />
                        </DivOrgValues>
                        <DivOrgValues>
                          <Value>Total de compras</Value>
                          <InfoValue>{infoOrders.totalOrders}</InfoValue>
                        </DivOrgValues>
                      </DivOrgInfo>
                    </DivInfoDetailsClient>
                  </DivDetailClient>
                  <DivBtn>
                    {/* <BtnDetail>Pagamento</BtnDetail> */}
                    <LinkPage
                      to="resume-clients"
                      state={{ idClient: infoOrders.idClient }}
                    >
                      Pedidos
                    </LinkPage>
                  </DivBtn>
                </DivInfoClientCard>
              );
            })
          )}
        </DivInfoClientTable>
      </DivInfoClient>
    </DivOrgSales>
  );
}
